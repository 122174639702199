// Global types
export type OverlayType = "side-menu" | "modal";

// declare global functions
declare global {
    interface Window {
        /* Overlay functions */
        toggleOverlay: (name: string, mode: OverlayType, allowCreate?: boolean, focusElement?: HTMLElement) => void;
        closeCurrentOverlay: (element: HTMLElement) => void;
        removeUrlParam: (paramName: string) => void;
    }
}

/**
 * Toggle a page overlay.
 *
 * @param name - The target overlay name
 * @param mode - The overlay mode
 * @param allowCreate - Try to create the overlay if not found
 */
window.toggleOverlay = (name, mode, allowCreate = true, focusElement): void => {
    const overlay = document.getElementById(`overlay-${mode}-${name}`) as HTMLDialogElement;

    /* Attempt to create the overlay */
    if (!overlay) {
        allowCreate && createOverlay(name, mode);
        return;
    }

    overlay.show();

    if (focusElement) {
        focusElement.focus();
    }

    setTimeout(() => {
        overlay.dataset.overlayState = "visible";

        // Enable after overlay visible to prevent a 'frozen screen' when scrolling just after the click.
        document.body.dataset.activeOverlay = "";
    }, 100);
};

/**
 * Removes an url parameter without refreshing the curent page.
 *
 * @param paramName The parameter name as shown in the url
 */
window.removeUrlParam = (paramName: string): void => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    params.delete(paramName);
    url.search = params.toString();

    window.history.replaceState({}, document.title, url);
};

/**
 *  Try to create an overlay if the name is registered in the page overlay component
 */
const createOverlay = (overlayName: string, mode: OverlayType) => {
    // @ts-ignore
    oc.request("#page-overlays", "onCreatePageOverlay", {
        data: {overlayName: overlayName},
        success: function (data: any) {
            this.success(data).done(function () {
                window.toggleOverlay(overlayName, mode, false);
            });
        },
    });
};

/**
 * Closes the overlay with a smooth animation defined in the CSS.
 *
 * @param overlay Any open <dialog> element.
 * @param disableActiveOverlay - removes the active overlay on the body if true.
 */
const closeOverlay = (overlay: HTMLDialogElement, disableActiveOverlay = false) => {
    if (!overlay) return;

    // Delete before overlay close for enhanced responsiveness
    if (disableActiveOverlay) delete document.body.dataset.activeOverlay;

    overlay.dataset.overlayState = "hidden";

    // TODO When closing a popup and opening it within 400ms, the 'open' attribute is missing on the dialog
    setTimeout(() => {
        overlay.close();
    }, 400);
};

/**
 * Closes the page overlay container
 *
 * @param element any HTML element inside a page overlay
 */
window.closeCurrentOverlay = (element: HTMLElement): void => {
    const parentDialog = element.closest("dialog");

    if (parentDialog) closeOverlay(parentDialog, true);
};

/**
 * Listen for clicks on active <dialog> elements.
 */
document.addEventListener("DOMContentLoaded", () => {
    // Check for overlays that should be triggered on boot
    const triggerOverlays = document.querySelectorAll("[data-overlay-trigger]") as NodeListOf<HTMLDialogElement>;

    for (const overlay of triggerOverlays) {
        const overlayType = overlay.dataset.overlayType as OverlayType;
        const overlayName = overlay.dataset.overlayName;

        if (overlayType && overlayName) {
            window.toggleOverlay(overlayName, overlayType);
        }
    }

    // Listen for clicks outside active overlays and close the top overlay
    document.addEventListener("mousedown", (e) => {
        const activeOverlays = document.querySelectorAll("dialog[open]") as NodeListOf<HTMLDialogElement>;
        const target = e.target as HTMLElement;

        if (activeOverlays.length > 0 && target.hasAttribute("data-overlay-close"))
            closeOverlay(activeOverlays[activeOverlays.length - 1], activeOverlays.length === 1);
    });

    // Close modals on escape key press
    document.addEventListener("keyup", (e) => {
        const activeOverlays = document.querySelectorAll("dialog[open]") as NodeListOf<HTMLDialogElement>;

        if (e.code === "Escape" && activeOverlays.length > 0)
            closeOverlay(activeOverlays[activeOverlays.length - 1], activeOverlays.length === 1);
    });
});
